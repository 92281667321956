<template>
  <v-container fluid>
    <v-row dense>
      <v-col
        cols="2"
      >
        <search-bar
          @narrowDownItems="narrowDownItems"
        />
      </v-col>
      <v-col
        cols="10"
        class="px-5"
      >
        <h1>{{ searchResultTitle }}</h1>
        <v-row
          v-if="items.length > 0"
          dense
        >
          <v-col
            v-for="item in items"
            :key="item.uuid"
            lg="3"
            md="4"
            sm="6"
          >
            <v-card
              :class="['outset', 'welfare-card', 'mx-3', 'my-5']"
            >
              <router-link :to="{ name: 'ItemDetail', params: { uuid: item.uuid }}">
                <v-img
                  v-if="item.mainImgSrc"
                  height="250"
                  :src="item.mainImgSrc"
                />
                <v-img
                  v-else
                  height="250"
                  src="@/assets/img/no_image.png"
                />
              </router-link>
              <v-col
                class="pa-0"
              >
                <div
                  class="d-flex align-center mt-2 pl-5 pr-2"
                >
                  <v-col
                    class="pa-0"
                    cols="9"
                  >
                    <router-link
                      :to="{ name: 'ItemDetail', params: { uuid: item.uuid }}"
                      class="text-decoration-none"
                    >
                      <v-col
                        class="pa-0"
                      >
                        <v-card-title
                          class="text-truncate d-block pa-0"
                          v-text="item.name"
                        />
                      </v-col>
                    </router-link>
                  </v-col>
                  <v-col
                    class="pa-0 text-center"
                    cols="3"
                  >
                    <v-btn
                      v-if="!item.isFavorite"
                      plain
                      class="pa-0"
                      color="white"
                      @click="addToFavorite(item.uuid)"
                    >
                      <v-icon
                        x-large
                        color="secondary"
                      >
                        mdi-heart-plus-outline
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      class="pa-0"
                      plain
                      color="white"
                      @click="removeFromFavorite(item.uuid)"
                    >
                      <v-icon
                        x-large
                        color="secondary"
                      >
                        mdi-heart-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </div>
              </v-col>
              <v-card-text
                class="ms-4"
              >
                {{ item.price.toLocaleString() }} pt
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-else
          justify="center"
          class="mt-10"
        >
          <div>
            <v-icon
              x-large
              color="primary"
            >
              mdi-gift-off-outline
            </v-icon>
            ご指定の検索条件に該当するアイテムは見つかりませんでした
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from '@/components/pages/user/item/list/SearchBarPresenter'

export default {
  components: {
    SearchBar
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    searchResultTitle: {
      type: String,
      required: true
    }
  },

  methods: {
    narrowDownItems (minPrice, maxPrice) {
      this.$emit('narrowDownItems', this, minPrice, maxPrice)
    },
    addToFavorite (itemUuid) {
      this.$emit('addToFavorite', this, itemUuid)
    },
    removeFromFavorite (itemUuid) {
      this.$emit('removeFromFavorite', this, itemUuid)
    }
  }
}
</script>
