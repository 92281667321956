import FavoriteItem from '@/models/FavoriteItem.js'
import store from '@/store'
import ListPresenter from '@/components/pages/user/item/list/ListPresenter'

export default {

  data () {
    return {
      items: [],
      keywords: []
    }
  },

  created () {
    this.execSearch()
  },

  watch: {
    $route (to, from) {
      this.execSearch()
      this.keywords = []
      to.query.category && this.keywords.push(`【${to.query.category}】`)
      to.query.keywords && this.keywords.push(to.query.keywords)
    }
  },

  methods: {
    async execSearch () {
      const params = {
        'keywords': this.$route.query.keywords,
        'category': this.$route.query.category,
        'minPrice': this.$route.query.minPrice,
        'maxPrice': this.$route.query.maxPrice
      }
      this.items = await this.$store.dispatch('item/getList', params)
        .catch(error => {
          if (error.response.status === 400) {
            store.commit('message/show', {
              message: error.response.data.message,
              color: 'error',
              timeout: 4000
            })
          }
        })
    }
  },

  render (h) {
    return h(ListPresenter, {
      props: {
        items: this.items,
        searchResultTitle: this.keywords.length ? `${this.keywords.join('')}の検索結果` : '検索結果'
      },

      on: {
        narrowDownItems (presenter, minPrice, maxPrice) {
          const queryParams = {
            'keywords': presenter.$route.query.keywords,
            'category': presenter.$route.query.category,
            'minPrice': minPrice,
            'maxPrice': maxPrice
          }
          presenter.$router.push({ name: 'ItemList', query: queryParams }, () => {})
        },
        addToFavorite (presenter, itemUuid) {
          const data = {
            itemUuid: itemUuid
          }
          store.dispatch('favoriteItem/addFavoriteItem', data)
            .then(() => {
              // Changed to true for the reason of switching screen display
              const targetItem = presenter.items.find(item => (item.uuid === itemUuid))
              targetItem.isFavorite = true
              store.commit('message/show', {
                message: 'お気に入りにアイテムを追加しました。',
                color: 'info',
                timeout: 4000
              })
            })
            .catch(error => {
              FavoriteItem.errorHandler(error)
            })
        },
        removeFromFavorite (presenter, itemUuid) {
          store.dispatch('favoriteItem/removeFavoriteItem', itemUuid)
            .then(() => {
              // Changed to false for the reason of switching screen display
              const targetItem = presenter.items.find(item => (item.uuid === itemUuid))
              targetItem.isFavorite = false
              store.commit('message/show', {
                message: 'お気に入りからアイテムを削除しました。',
                color: 'info',
                timeout: 4000
              })
            })
            .catch(error => {
              FavoriteItem.errorHandler(error)
            })
        }
      }
    })
  }
}
