<template>
  <v-card
    :class="['inset', 'welfare-card', 'mt-5', 'mx-5']"
  >
    <v-card-title>
      絞り込み
    </v-card-title>
    <div
      class="mx-10"
    >
      <v-card-subtitle>
        ポイント数
      </v-card-subtitle>
      <v-text-field
        v-model="minPrice"
        label="最小pt"
        type="number"
      />〜
      <v-text-field
        v-model="maxPrice"
        label="最大pt"
        type="number"
      />

      <v-card-actions>
        <v-btn
          outlined
          rounded
          color="primary"
          :class="['small-outset', 'my-10']"
          @click="narrowDownItems"
        >
          絞り込む
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>

export default {
  data: () => ({
    minPrice: '',
    maxPrice: ''
  }),

  methods: {
    narrowDownItems () {
      this.$emit('narrowDownItems', this.minPrice, this.maxPrice)
    }
  }
}
</script>
